import { ChainId } from "common";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { votingTokensMap } from "./features/api/utils";
import { zeroAddress } from "viem";
/**
 * Consumes an array of voting tokens and returns the default one.
 * If there's no default one, return the first one.
 * If the array is empty,
 * return the native token for the chain (Although this should never happen)
 * */
function getDefaultVotingToken(votingTokens, chainId) {
  var _ref, _votingTokens$find;
  return (_ref = (_votingTokens$find = votingTokens.find(token => token.defaultForVoting && token.canVote)) !== null && _votingTokens$find !== void 0 ? _votingTokens$find : votingTokens[0]) !== null && _ref !== void 0 ? _ref : {
    chainId,
    canVote: true,
    defaultForVoting: true,
    decimal: 18,
    name: "Native Token",
    address: zeroAddress
  };
}
const defaultVotingTokens = Object.fromEntries(Object.entries(votingTokensMap).map(_ref2 => {
  let [key, value] = _ref2;
  return [Number(key), getDefaultVotingToken(value, Number(key))];
}));
function isSameProject(a, b) {
  return a.grantApplicationId.toLowerCase() === b.grantApplicationId.toLowerCase() && a.chainId === b.chainId && a.roundId === b.roundId;
}
function updateOrInsertCartProject(currentProjects, newProject) {
  const initialAcc = {
    projects: [],
    hasUpdatedProject: false
  };
  const result = currentProjects.reduce((acc, project) => {
    if (isSameProject(project, newProject)) {
      return {
        projects: [...acc.projects, newProject],
        hasUpdatedProject: true
      };
    } else {
      return {
        ...acc,
        projects: [...acc.projects, project]
      };
    }
  }, initialAcc);
  return result.hasUpdatedProject ? result.projects : [...currentProjects, newProject];
}
export const useCartStorage = create()(persist((set, get) => ({
  projects: [],
  add: newProject => {
    const currentProjects = get().projects;
    set({
      projects: updateOrInsertCartProject(currentProjects, newProject)
    });
  },
  /** @param grantApplicationId - ${roundAddress}-${applicationId} */
  remove: projectToRemove => {
    set({
      projects: get().projects.filter(proj => proj.grantApplicationId !== projectToRemove.grantApplicationId || proj.chainId !== projectToRemove.chainId || proj.roundId !== projectToRemove.roundId)
    });
  },
  clear: () => {
    set({
      projects: []
    });
  },
  updateDonationsForChain: (chainId, amount) => {
    const newState = get().projects.map(project => ({
      ...project,
      amount: project.chainId === chainId ? amount : project.amount
    }));
    set({
      projects: newState
    });
  },
  updateDonationAmount: (chainId, roundId, grantApplicationId, amount) => {
    if (amount.includes("-")) {
      return;
    }
    const projectIndex = get().projects.findIndex(donation => donation.chainId === chainId && donation.roundId === roundId && donation.grantApplicationId === grantApplicationId);
    if (projectIndex !== -1) {
      const newState = [...get().projects];
      newState[projectIndex].amount = amount;
      set({
        projects: newState
      });
    }
  },
  chainToVotingToken: defaultVotingTokens,
  getVotingTokenForChain: chainId => {
    const tokenFromStore = get().chainToVotingToken[chainId];
    if (!tokenFromStore) {
      const defaultToken = getDefaultVotingToken(votingTokensMap[chainId], chainId);
      console.log("no token for chain", chainId, " defaulting to ", defaultToken, " and setting it as the default token for that chain");
      get().setVotingTokenForChain(chainId, defaultToken);
      return defaultToken;
    } else {
      return tokenFromStore;
    }
  },
  setVotingTokenForChain: (chainId, payoutToken) => {
    if (!Object.values(ChainId).includes(chainId)) {
      if (process.env.NODE_ENV !== "test") {
        console.warn("Tried setting payoutToken", payoutToken, "for chain", chainId, ", but chain", chainId, " doesn't exist");
      }
      return;
    }
    set({
      chainToVotingToken: {
        ...get().chainToVotingToken,
        [chainId]: payoutToken
      }
    });
  }
}), {
  name: "cart-storage",
  version: 3
}));
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ProgressStatus } from "./features/api/types";
import { useCartStorage } from "./store";
import { InternalRpcError, parseAbi, parseUnits, SwitchChainError, UserRejectedRequestError, zeroAddress } from "viem";
import { encodeQFVotes, encodedQFAllocation, signPermit2612, signPermitDai } from "./features/api/voting";
import { groupBy, uniq } from "lodash-es";
import { datadogLogs } from "@datadog/browser-logs";
import { getEnabledChains } from "./app/chainConfig";
import { getContract, getPublicClient } from "@wagmi/core";
import { getPermitType } from "common/dist/allo/voting";
import { MRC_CONTRACTS } from "common/dist/allo/addresses/mrc";
import { getConfig } from "common/src/config";
const isV2 = getConfig().allo.version === "allo-v2";
const defaultProgressStatusForAllChains = Object.fromEntries(Object.values(getEnabledChains()).map(value => [value.id, ProgressStatus.NOT_STARTED]));
export const useCheckoutStore = create()(devtools((set, get) => ({
  permitStatus: defaultProgressStatusForAllChains,
  setPermitStatusForChain: (chain, permitStatus) => set(oldState => ({
    permitStatus: {
      ...oldState.permitStatus,
      [chain]: permitStatus
    }
  })),
  voteStatus: defaultProgressStatusForAllChains,
  setVoteStatusForChain: (chain, voteStatus) => set(oldState => ({
    voteStatus: {
      ...oldState.voteStatus,
      [chain]: voteStatus
    }
  })),
  chainSwitchStatus: defaultProgressStatusForAllChains,
  setChainSwitchStatusForChain: (chain, chainSwitchStatus) => set(oldState => ({
    chainSwitchStatus: {
      ...oldState.chainSwitchStatus,
      [chain]: chainSwitchStatus
    }
  })),
  currentChainBeingCheckedOut: undefined,
  chainsToCheckout: [],
  setChainsToCheckout: chains => {
    set({
      chainsToCheckout: chains
    });
  },
  checkout: async (chainsToCheckout, walletClient, allo) => {
    const chainIdsToCheckOut = chainsToCheckout.map(chain => chain.chainId);
    get().setChainsToCheckout(uniq([...get().chainsToCheckout, ...chainIdsToCheckOut]));
    const projectsToCheckOut = useCartStorage.getState().projects.filter(project => chainIdsToCheckOut.includes(project.chainId));
    const projectsByChain = groupBy(projectsToCheckOut, "chainId");
    const getVotingTokenForChain = useCartStorage.getState().getVotingTokenForChain;
    const totalDonationPerChain = Object.fromEntries(Object.entries(projectsByChain).map(_ref => {
      let [key, value] = _ref;
      return [Number(key), value.map(project => project.amount).reduce((acc, amount) => acc + parseUnits(amount ? amount : "0", getVotingTokenForChain(Number(key)).decimal), 0n)];
    }));

    /* Main chain loop */
    for (const currentChain of chainsToCheckout) {
      const chainId = currentChain.chainId;
      const deadline = currentChain.permitDeadline;
      const donations = projectsByChain[chainId];
      set({
        currentChainBeingCheckedOut: chainId
      });

      /* Switch to the current chain */
      await switchToChain(chainId, walletClient, get);
      const token = getVotingTokenForChain(chainId);
      let sig;
      let nonce;
      if (token.address !== zeroAddress) {
        /* Need permit */
        try {
          get().setPermitStatusForChain(chainId, ProgressStatus.IN_PROGRESS);
          const owner = walletClient.account.address;
          /* Get nonce and name from erc20 contract */
          const erc20Contract = getContract({
            address: token.address,
            abi: parseAbi(["function nonces(address) public view returns (uint256)", "function name() public view returns (string)"]),
            walletClient,
            chainId
          });
          nonce = await erc20Contract.read.nonces([owner]);
          const tokenName = await erc20Contract.read.name();
          if (getPermitType(token) === "dai") {
            var _token$permitVersion;
            sig = await signPermitDai({
              walletClient: walletClient,
              spenderAddress: MRC_CONTRACTS[chainId],
              chainId,
              deadline: BigInt(deadline),
              contractAddress: token.address,
              erc20Name: tokenName,
              ownerAddress: owner,
              nonce,
              permitVersion: (_token$permitVersion = token.permitVersion) !== null && _token$permitVersion !== void 0 ? _token$permitVersion : "1"
            });
          } else {
            var _token$permitVersion2;
            sig = await signPermit2612({
              walletClient: walletClient,
              value: totalDonationPerChain[chainId],
              spenderAddress: MRC_CONTRACTS[chainId],
              nonce,
              chainId,
              deadline: BigInt(deadline),
              contractAddress: token.address,
              erc20Name: tokenName,
              ownerAddress: owner,
              permitVersion: (_token$permitVersion2 = token.permitVersion) !== null && _token$permitVersion2 !== void 0 ? _token$permitVersion2 : "1"
            });
          }
          get().setPermitStatusForChain(chainId, ProgressStatus.IS_SUCCESS);
        } catch (e) {
          console.error(e);
          get().setPermitStatusForChain(chainId, ProgressStatus.IS_ERROR);
          return;
        }
        if (!sig) {
          get().setPermitStatusForChain(chainId, ProgressStatus.IS_ERROR);
          return;
        }
      }
      try {
        /** When voting via native token, we just set the permit status to success */
        if (!sig) {
          get().setPermitStatusForChain(chainId, ProgressStatus.IS_SUCCESS);
        }
        get().setVoteStatusForChain(chainId, ProgressStatus.IN_PROGRESS);

        /* Group donations by round */
        const groupedDonations = groupBy(donations.map(d => ({
          ...d,
          roundId: d.roundId
        })), "roundId");
        const groupedEncodedVotes = {};
        for (const roundId in groupedDonations) {
          groupedEncodedVotes[roundId] = isV2 ? encodedQFAllocation(token, groupedDonations[roundId]) : encodeQFVotes(token, groupedDonations[roundId]);
        }
        const groupedAmounts = {};
        for (const roundId in groupedDonations) {
          groupedAmounts[roundId] = groupedDonations[roundId].reduce((acc, donation) => acc + parseUnits(donation.amount, token.decimal), 0n);
        }
        const amountArray = [];
        for (const roundId in groupedDonations) {
          groupedDonations[roundId].map(donation => {
            amountArray.push(parseUnits(donation.amount, token.decimal));
          });
        }
        const receipt = await allo.donate(getPublicClient({
          chainId
        }), chainId, token, groupedEncodedVotes, isV2 ? amountArray : groupedAmounts, totalDonationPerChain[chainId], sig ? {
          sig,
          deadline,
          nonce: nonce
        } : undefined);
        if (receipt.status === "reverted") {
          console.error(`vote on chain ${chainId} - roundIds ${Object.keys(donations.map(d => d.roundId))}, token ${token.name}`, receipt, sig, token);
          throw new Error("vote failed", {
            cause: receipt
          });
        }
        console.log("Voting successful for chain", chainId, " receipt", receipt);
        /* Remove checked out projects from cart */
        donations.forEach(donation => {
          useCartStorage.getState().remove(donation);
        });
        set(oldState => ({
          voteStatus: {
            ...oldState.voteStatus,
            [chainId]: ProgressStatus.IS_SUCCESS
          }
        }));
        set({
          checkedOutProjects: [...get().checkedOutProjects, ...donations]
        });
      } catch (error) {
        datadogLogs.logger.error(`error: vote - ${error}. Data - ${donations.toString()}`);
        console.error(`vote on chain ${chainId} - roundIds ${Object.keys(donations.map(d => d.roundId))}, token ${token.name}`, error);
        get().setVoteStatusForChain(chainId, ProgressStatus.IS_ERROR);
        throw error;
      }
    }
    /* End main chain loop*/
  },

  checkedOutProjects: [],
  getCheckedOutProjects: () => {
    return get().checkedOutProjects;
  },
  setCheckedOutProjects: newArray => {
    set({
      checkedOutProjects: newArray
    });
  }
})));

/** This function handles switching to a chain
 * if the chain is not present in the wallet, it will add it, and then switch */
async function switchToChain(chainId, walletClient, get) {
  get().setChainSwitchStatusForChain(chainId, ProgressStatus.IN_PROGRESS);
  const nextChainData = getEnabledChains().find(chain => chain.id === chainId);
  if (!nextChainData) {
    get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_ERROR);
    throw "next chain not found";
  }
  try {
    /* Try switching normally */
    await walletClient.switchChain({
      id: chainId
    });
  } catch (e) {
    if (e instanceof UserRejectedRequestError) {
      console.log("Rejected!");
      get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_ERROR);
      return;
    } else if (e instanceof SwitchChainError || e instanceof InternalRpcError) {
      console.log("Chain not added yet, adding", {
        e
      });
      /** Chain might not be added in wallet yet. Request to add it to the wallet */
      try {
        await walletClient.addChain({
          chain: {
            id: nextChainData.id,
            name: nextChainData.name,
            network: nextChainData.network,
            nativeCurrency: nextChainData.nativeCurrency,
            rpcUrls: nextChainData.rpcUrls,
            blockExplorers: nextChainData.blockExplorers
          }
        });
      } catch (e) {
        get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_ERROR);
        return;
      }
    } else {
      console.log("unhandled error when switching chains", {
        e
      });
      get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_ERROR);
      return;
    }
  }
  get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_SUCCESS);
}
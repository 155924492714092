import { ChainId } from "../../chain-ids";
import { Address } from "viem";

/**
 * This file contains all contract definitions for Allo v1
 */
type ChainIdToStringMap = Record<ChainId, Address>;

export const projectRegistryMap: ChainIdToStringMap = {
  [ChainId.DEV1]: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
  [ChainId.DEV2]: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
  [ChainId.MAINNET]: "0x03506eD3f57892C85DB20C36846e9c808aFe9ef4",
  [ChainId.OPTIMISM_MAINNET_CHAIN_ID]:
    "0x8e1bD5Da87C14dd8e08F7ecc2aBf9D1d558ea174",
  [ChainId.FANTOM_MAINNET_CHAIN_ID]:
    "0x8e1bD5Da87C14dd8e08F7ecc2aBf9D1d558ea174",
  [ChainId.FANTOM_TESTNET_CHAIN_ID]:
    "0x984749e408FF0446d8ADaf20E293F2F299396631",
  [ChainId.PGN_TESTNET]: "0x6294bed5B884Ae18bf737793Ef9415069Bf4bc11",
  [ChainId.PGN]: "0xDF9BF58Aa1A1B73F0e214d79C652a7dd37a6074e",
  [ChainId.ARBITRUM]: "0x73AB205af1476Dc22104A6B8b3d4c273B58C6E27",
  [ChainId.ARBITRUM_GOERLI]: "0x0CD135777dEaB6D0Bb150bDB0592aC9Baa4d0871",
  [ChainId.FUJI]: "0x8918401DD47f1645fF1111D8E513c0404b84d5bB",
  [ChainId.AVALANCHE]: "0xDF9BF58Aa1A1B73F0e214d79C652a7dd37a6074e",
  [ChainId.POLYGON]: "0x5C5E2D94b107C7691B08E43169fDe76EAAB6D48b",
  [ChainId.POLYGON_MUMBAI]: "0x545B282A50EaeA01A619914d44105437036CbB36",
  [ChainId.ZKSYNC_ERA_MAINNET_CHAIN_ID]:
    "0xe6CCEe93c97E20644431647B306F48e278aFFdb9",
  [ChainId.ZKSYNC_ERA_TESTNET_CHAIN_ID]:
    "0xb0F4882184EB6e3ed120c5181651D50719329788",
  [ChainId.BASE]: "0xA78Daa89fE9C1eC66c5cB1c5833bC8C6Cb307918",
  [ChainId.SEPOLIA]: "0x2420EABfA2C0e6f77E435B0B7615c848bF4963AF",
  [ChainId.SCROLL]: "0xDF9BF58Aa1A1B73F0e214d79C652a7dd37a6074e",
  [ChainId.SEI_DEVNET]: "0x",
};

export const programFactoryMap: ChainIdToStringMap = {
  [ChainId.DEV1]: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
  [ChainId.DEV2]: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
  [ChainId.MAINNET]: "0x56296242CA408bA36393f3981879fF9692F193cC",
  [ChainId.OPTIMISM_MAINNET_CHAIN_ID]:
    "0xd5Fb00093Ebd30011d932cB69bb6313c550aB05f",
  [ChainId.FANTOM_MAINNET_CHAIN_ID]:
    "0x4d1f64c7920262c8F78e989C9E7Bf48b7eC02Eb5",
  [ChainId.FANTOM_TESTNET_CHAIN_ID]:
    "0x424C5C175fbd46CA0b27866044A5B956c6AbEe0D",
  [ChainId.PGN_TESTNET]: "0x2Ff06F96Bb265698e47BfdED83f1aa0aC7c3a4Ce",
  [ChainId.PGN]: "0xd07D54b0231088Ca9BF7DA6291c911B885cBC140",
  [ChainId.ARBITRUM]: "0xDF9BF58Aa1A1B73F0e214d79C652a7dd37a6074e",
  [ChainId.ARBITRUM_GOERLI]: "0xd39b40aC9279EeeB86FBbDeb2C9acDF16e16cF89",
  [ChainId.FUJI]: "0x862D7F621409cF572f179367DdF1B7144AcE1c76",
  [ChainId.AVALANCHE]: "0xd07D54b0231088Ca9BF7DA6291c911B885cBC140",
  [ChainId.POLYGON]: "0xF7c101A95Ea4cBD5DA0Ab9827D7B2C9857440143",
  [ChainId.POLYGON_MUMBAI]: "0xDF9BF58Aa1A1B73F0e214d79C652a7dd37a6074e",
  [ChainId.ZKSYNC_ERA_MAINNET_CHAIN_ID]:
    "0x68a14AF71BFa0FE09fC937033f6Ea5153c0e75e4",
  [ChainId.ZKSYNC_ERA_TESTNET_CHAIN_ID]:
    "0x6D341814Be4E2316142D9190E390b494F1dECFAf",
  [ChainId.BASE]: "0xDF9BF58Aa1A1B73F0e214d79C652a7dd37a6074e",
  [ChainId.SEPOLIA]: "0x79Ba35cb31620db1b5b101A9A13A1b0A82B5BC9e",
  [ChainId.SCROLL]: "0x545B282A50EaeA01A619914d44105437036CbB36",
  [ChainId.SEI_DEVNET]: "0x",
};

export const roundFactoryMap: ChainIdToStringMap = {
  [ChainId.DEV1]: "0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f",
  [ChainId.DEV2]: "0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f",
  [ChainId.MAINNET]: "0x9Cb7f434aD3250d1656854A9eC7A71EceC6eE1EF",
  [ChainId.OPTIMISM_MAINNET_CHAIN_ID]:
    "0x04E753cFB8c8D1D7f776f7d7A033740961b6AEC2",
  [ChainId.FANTOM_MAINNET_CHAIN_ID]:
    "0xfb08d1fD3a7c693677eB096E722ABf4Ae63B0B95",
  [ChainId.FANTOM_TESTNET_CHAIN_ID]:
    "0x8AdFcF226dfb2fA73788Ad711C958Ba251369cb3",
  [ChainId.PGN_TESTNET]: "0x0479b9DA9f287539FEBd597350B1eBaEBF7479ac",
  [ChainId.PGN]: "0x8AdFcF226dfb2fA73788Ad711C958Ba251369cb3",
  [ChainId.ARBITRUM_GOERLI]: "0xdf25423c9ec15347197Aa5D3a41c2ebE27587D59",
  [ChainId.ARBITRUM]: "0xF2a07728107B04266015E67b1468cA0a536956C8",
  [ChainId.FUJI]: "0x3615d870d5B760cea43693ABED70Cd8A9b59b3d8",
  [ChainId.AVALANCHE]: "0x8eC471f30cA797FD52F9D37A47Be2517a7BD6912",
  [ChainId.POLYGON]: "0x5ab68dCdcA37A1C2b09c5218e28eB0d9cc3FEb03",
  [ChainId.POLYGON_MUMBAI]: "0xE1c5812e9831bc1d5BDcF50AAEc1a47C4508F3fA",
  [ChainId.ZKSYNC_ERA_MAINNET_CHAIN_ID]:
    "0xF3B5a0d59C6292BD0e4f8Cf735EEF52b98f428E6",
  [ChainId.ZKSYNC_ERA_TESTNET_CHAIN_ID]:
    "0x0Bb6e2dfEaef0Db5809B3979717E99e053Cbae72",
  [ChainId.BASE]: "0xc7722909fEBf7880E15e67d563E2736D9Bb9c1Ab",
  [ChainId.SEPOLIA]: "0xF1d4F5f21746bCD75fD71eB18992443f4F0edb6f",
  [ChainId.SCROLL]: "0x29aAF7D4E83A778DAee08Fe04B0712c4C2989AD1",
  [ChainId.SEI_DEVNET]: "0x",
};

export const qfVotingStrategyFactoryMap: ChainIdToStringMap = {
  [ChainId.DEV1]: "0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e",
  [ChainId.DEV2]: "0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e",
  [ChainId.MAINNET]: "0x4a850F463D1C4842937c5Bc9540dBc803D744c9F",
  [ChainId.OPTIMISM_MAINNET_CHAIN_ID]:
    "0x838C5e10dcc1e54d62761d994722367BA167AC22",
  [ChainId.FANTOM_MAINNET_CHAIN_ID]:
    "0x534d2AAc03dCd0Cb3905B591BAf04C14A95426AB",
  [ChainId.FANTOM_TESTNET_CHAIN_ID]:
    "0x545B282A50EaeA01A619914d44105437036CbB36",
  [ChainId.PGN_TESTNET]: "0xE8027a807Bb85e57da4B7A5ecE65b0aBDf231ce8",
  [ChainId.PGN]: "0x2AFA4bE0f2468347A2F086c2167630fb1E58b725",
  [ChainId.ARBITRUM_GOERLI]: "0x0BFA0AAF5f2D81f859e85C8E82A3fc5b624fc6E8",
  [ChainId.ARBITRUM]: "0xC3A195EEa198e74D67671732E1B8F8A23781D735",
  [ChainId.FUJI]: "0xd39b40aC9279EeeB86FBbDeb2C9acDF16e16cF89",
  [ChainId.AVALANCHE]: "0x2AFA4bE0f2468347A2F086c2167630fb1E58b725",
  [ChainId.POLYGON]: "0xc1a26b0789C3E93b07713e90596Cad8d0442C826",
  [ChainId.POLYGON_MUMBAI]: "0xF7c101A95Ea4cBD5DA0Ab9827D7B2C9857440143",
  [ChainId.ZKSYNC_ERA_MAINNET_CHAIN_ID]:
    "0x94cB638556d3991363102431d8cE9e839C734677",
  [ChainId.ZKSYNC_ERA_TESTNET_CHAIN_ID]:
    "0x8c28F21D2d8C53eedC58bF9cdCfb7DCF7d809d97",
  [ChainId.BASE]: "0xC3A195EEa198e74D67671732E1B8F8A23781D735",
  [ChainId.SEPOLIA]: "0xf5D111B57de221774866AC32c4435841F5c141D5",
  [ChainId.SCROLL]: "0x5b55728e41154562ee80027C1247B13382692e5C",
  [ChainId.SEI_DEVNET]: "0x",
};

export const dgVotingStrategyDummyContractMap: ChainIdToStringMap = {
  [ChainId.DEV1]: "0x",
  [ChainId.DEV2]: "0x",
  [ChainId.MAINNET]: "0xDF9BF58Aa1A1B73F0e214d79C652a7dd37a6074e",
  [ChainId.OPTIMISM_MAINNET_CHAIN_ID]:
    "0xB9fd0d433d2ca03D26A182Dc709bA1EccA3B00cC",
  [ChainId.FANTOM_MAINNET_CHAIN_ID]:
    "0xB91749077A0dE932a4AE2b882d846ef9C53b9505",
  [ChainId.FANTOM_TESTNET_CHAIN_ID]:
    "0xc7722909fEBf7880E15e67d563E2736D9Bb9c1Ab",
  [ChainId.PGN_TESTNET]: "0xDF9BF58Aa1A1B73F0e214d79C652a7dd37a6074e",
  [ChainId.PGN]: "0xcE7c30DbcEC2a98B516E4C64fA4E3256AB813b10",
  [ChainId.ARBITRUM_GOERLI]: "0x809E751e5C5bB1446e9ab2Ac37c687a35DE53BC6",
  [ChainId.ARBITRUM]: "0x5ab68dCdcA37A1C2b09c5218e28eB0d9cc3FEb03",
  [ChainId.FUJI]: "0xCd3618509983FE4990D7770CF6f02c7145dC365F",
  [ChainId.AVALANCHE]: "0xA78Daa89fE9C1eC66c5cB1c5833bC8C6Cb307918",
  [ChainId.POLYGON]: "0x8142cAa6dED9F63434B1ED862d53E06332874570",
  [ChainId.POLYGON_MUMBAI]: "0xA78Daa89fE9C1eC66c5cB1c5833bC8C6Cb307918",
  [ChainId.ZKSYNC_ERA_MAINNET_CHAIN_ID]:
    "0x787D662D19C9528EB33FdaBb3cBEcBeAb2a7F15a",
  [ChainId.ZKSYNC_ERA_TESTNET_CHAIN_ID]:
    "0x0c0B71BA1427cb46424d38133E8187365Cc5466b",
  [ChainId.BASE]: "0x73AB205af1476Dc22104A6B8b3d4c273B58C6E27",
  [ChainId.SEPOLIA]: "0xB9dDcd73278C7f472867bf3D844E2Cc53a3d3D90",
  [ChainId.SCROLL]: "0x1525370A2B92FB5e8D0a44F3A9833f0E9F1a54b5",
  [ChainId.SEI_DEVNET]: "0x",
};

export const merklePayoutStrategyFactoryMap: ChainIdToStringMap = {
  [ChainId.DEV1]: "0x0B306BF915C4d645ff596e518fAf3F9669b97016",
  [ChainId.DEV2]: "0x0B306BF915C4d645ff596e518fAf3F9669b97016",
  [ChainId.MAINNET]: "0x8F8d78f119Aa722453d33d6881f4D400D67D054F",
  [ChainId.OPTIMISM_MAINNET_CHAIN_ID]:
    "0xB5365543cdDa2C795AD104F4cB784EF3DB1CD383",
  [ChainId.FANTOM_MAINNET_CHAIN_ID]:
    "0xFA1D9FF7F885757fc20Fdd9D78B72F88B00Cff77",
  [ChainId.FANTOM_TESTNET_CHAIN_ID]:
    "0x5b55728e41154562ee80027C1247B13382692e5C",
  [ChainId.PGN_TESTNET]: "0xE42D1Da8d75Cf1d6f6C460DAa3f1b10a79D689B1",
  [ChainId.PGN]: "0x27efa1C90e097c980c669AB1a6e326AD4164f1Cb",
  [ChainId.ARBITRUM_GOERLI]: "0x8F8d78f119Aa722453d33d6881f4D400D67D054F",
  [ChainId.ARBITRUM]: "0x04b194b14532070F5cc8D3A760c9a0957D85ad5B",
  [ChainId.FUJI]: "0x8F8d78f119Aa722453d33d6881f4D400D67D054F",
  [ChainId.AVALANCHE]: "0x27efa1C90e097c980c669AB1a6e326AD4164f1Cb",
  [ChainId.POLYGON]: "0xD0e19DBF9b896199F35Df255A1bf8dB3C787531c",
  [ChainId.POLYGON_MUMBAI]: "0xc1a26b0789C3E93b07713e90596Cad8d0442C826",
  [ChainId.ZKSYNC_ERA_MAINNET_CHAIN_ID]:
    "0x41A8F19C6CB88C9Cc98d29Cb7A4015629910fFc0",
  [ChainId.ZKSYNC_ERA_TESTNET_CHAIN_ID]:
    "0xbA160C13F8F626e3232078aDFD6eD2f2B2289563",
  [ChainId.BASE]: "0xF7c101A95Ea4cBD5DA0Ab9827D7B2C9857440143",
  [ChainId.SEPOLIA]: "0xB5CF3fFD3BDfC6A124aa9dD96fE14118Ed8083e5",
  [ChainId.SCROLL]: "0x7ac74Be34b1A27E48a2525259719F877a57B2Aa4",
  [ChainId.SEI_DEVNET]: "0x",
};

export const directPayoutStrategyFactoryContractMap: ChainIdToStringMap = {
  [ChainId.DEV1]: "0x",
  [ChainId.DEV2]: "0x",
  [ChainId.MAINNET]: "0xd07D54b0231088Ca9BF7DA6291c911B885cBC140",
  [ChainId.OPTIMISM_MAINNET_CHAIN_ID]:
    "0x2Bb670C3ffC763b691062d671b386E51Cf1840f0",
  [ChainId.FANTOM_MAINNET_CHAIN_ID]:
    "0x9B1Ee60B539a3761E328a621A3d980EE9385679a",
  [ChainId.FANTOM_TESTNET_CHAIN_ID]:
    "0x8eC471f30cA797FD52F9D37A47Be2517a7BD6912",
  [ChainId.PGN_TESTNET]: "0x3D77E65aEA55C0e07Cb018aB4Dc22D38cAD75921",
  [ChainId.PGN]: "0x0c33c9dEF7A3d9961b802C6C6402d306b7D48135",
  [ChainId.ARBITRUM_GOERLI]: "0xCd3618509983FE4990D7770CF6f02c7145dC365F",
  [ChainId.ARBITRUM]: "0xc1a26b0789C3E93b07713e90596Cad8d0442C826",
  [ChainId.FUJI]: "0x0F98547e09D41e3c82086fC5Eb0E42Ab786aA763",
  [ChainId.AVALANCHE]: "0x8AdFcF226dfb2fA73788Ad711C958Ba251369cb3",
  [ChainId.POLYGON]: "0xF2a07728107B04266015E67b1468cA0a536956C8",
  [ChainId.POLYGON_MUMBAI]: "0xD9B7Ce1F68A93dF783A8519ed52b74f5DcF5AFE1",
  [ChainId.ZKSYNC_ERA_MAINNET_CHAIN_ID]:
    "0x0ccdfCB7e5DB60AAE5667d1680B490F7830c49C8",
  [ChainId.ZKSYNC_ERA_TESTNET_CHAIN_ID]:
    "0x4170665B31bC10009f8a69CeaACf3265C3d66797",
  [ChainId.BASE]: "0x74c3665540FC8B92Dd06a7e56a51eCa038C18180",
  [ChainId.SEPOLIA]: "0xC2B0d8dAdB88100d8509534BB8B5778d1901037d",
  [ChainId.SCROLL]: "0xc7722909fEBf7880E15e67d563E2736D9Bb9c1Ab",
  [ChainId.SEI_DEVNET]: "0x",
};

import { __deprecated_fetchFromIPFS, __deprecated_graphql_fetch } from "./utils";
import { useEffect, useState } from "react";
import { getAddress } from "viem";
import { useDataLayer } from "data-layer";
import { getPublicClient } from "@wagmi/core";

/**
 * Shape of subgraph response
 */

/**
 * Shape of subgraph response of Round
 */

/**
 * Shape of IPFS content of Round RoundMetaPtr
 */

export async function __deprecated_getRoundById(roundId,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
chainId) {
  try {
    // get the subgraph for round by $roundId
    const res = await __deprecated_graphql_fetch(`
        query GetRoundById($roundId: String) {
          rounds(where: {
            id: $roundId
          }) {
            id
            program {
              id
            }
            roundMetaPtr {
              protocol
              pointer
            }
            applicationMetaPtr {
              protocol
              pointer
            }
            applicationsStartTime
            applicationsEndTime
            roundStartTime
            roundEndTime
            token
            payoutStrategy {
              id
              strategyName
            }
            votingStrategy
            projectsMetaPtr {
              pointer
            }
            projects(
              first: 1000
              where:{
                status: 1
              }
            ) {
              id
              project
              status
              applicationIndex
              metaPtr {
                protocol
                pointer
              }
            }
          }
        }
      `, chainId, {
      roundId: roundId.toLowerCase()
    });
    const round = res.data.rounds[0];
    const roundMetadata = await __deprecated_fetchFromIPFS(round.roundMetaPtr.pointer);
    round.projects = round.projects.map(project => {
      return {
        ...project,
        status: __deprecated_convertStatus(project.status)
      };
    });
    const approvedProjectsWithMetadata = await __deprecated_loadApprovedProjectsMetadata(round, chainId);
    return {
      id: roundId,
      roundMetadata,
      applicationsStartTime: new Date(parseInt(round.applicationsStartTime) * 1000),
      applicationsEndTime: new Date(parseInt(round.applicationsEndTime) * 1000),
      roundStartTime: new Date(parseInt(round.roundStartTime) * 1000),
      roundEndTime: new Date(parseInt(round.roundEndTime) * 1000),
      token: round.token,
      payoutStrategy: round.payoutStrategy,
      votingStrategy: round.votingStrategy,
      ownedBy: round.program.id,
      approvedProjects: approvedProjectsWithMetadata
    };
  } catch (error) {
    console.error("getRoundById", error);
    throw Error("Unable to fetch round");
  }
}
export function __deprecated_convertStatus(status) {
  switch (status) {
    case 0:
      return "PENDING";
    case 1:
      return "APPROVED";
    case 2:
      return "REJECTED";
    case 3:
      return "CANCELLED";
    default:
      return "PENDING";
  }
}
async function __deprecated_loadApprovedProjectsMetadata(round,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
chainId) {
  if (round.projects.length === 0) {
    return [];
  }
  const approvedProjects = round.projects;
  const fetchApprovedProjectMetadata = approvedProjects.map(project => __deprecated_fetchMetadataAndMapProject(project, chainId));
  return Promise.all(fetchApprovedProjectMetadata);
}
async function __deprecated_fetchMetadataAndMapProject(project,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
chainId) {
  const applicationData = await __deprecated_fetchFromIPFS(project.metaPtr.pointer);
  // NB: applicationData can be in two formats:
  // old format: { round, project, ... }
  // new format: { signature: "...", application: { round, project, ... } }
  const application = applicationData.application || applicationData;
  const projectMetadataFromApplication = application.project;
  const projectRegistryId = `0x${projectMetadataFromApplication.id}`;
  const projectOwners = await __deprecated_getProjectOwners(chainId, projectRegistryId);
  return {
    grantApplicationId: project.id,
    grantApplicationFormAnswers: application.answers,
    projectRegistryId: project.project,
    recipient: application.recipient,
    projectMetadata: {
      ...projectMetadataFromApplication,
      owners: projectOwners.map(address => ({
        address
      }))
    },
    status: "APPROVED",
    applicationIndex: project.applicationIndex
  };
}
export async function __deprecated_getProjectOwners(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
chainId, projectRegistryId) {
  try {
    var _res$data;
    // get the subgraph for project owners by $projectRegistryId
    const res = await __deprecated_graphql_fetch(`
        query GetProjectOwners($projectRegistryId: String) {
          projects(where: {
            id: $projectRegistryId
          }) {
            id
            accounts {
              account {
                address
              }
            }
          }
        }
      `, chainId, {
      projectRegistryId
    }, true);
    return ((_res$data = res.data) === null || _res$data === void 0 || (_res$data = _res$data.projects[0]) === null || _res$data === void 0 ? void 0 : _res$data.accounts.map(account => getAddress(account.account.address))) || [];
  } catch (error) {
    console.log("getProjectOwners", error);
    throw Error("Unable to fetch project owners");
  }
}
export const useContributionHistory = (chainIds, rawAddress) => {
  const [state, setState] = useState({
    type: "loading"
  });
  const dataLayer = useDataLayer();
  useEffect(() => {
    if (!process.env.REACT_APP_ALLO_API_URL) {
      throw new Error("REACT_APP_ALLO_API_URL is not set");
    }
    const fetchContributions = async () => {
      let address = "0x";
      try {
        address = getAddress(rawAddress.toLowerCase());
      } catch (e) {
        return Promise.resolve({
          chainIds,
          error: "Invalid address",
          data: []
        });
      }
      const contributions = await dataLayer.getDonationsByDonorAddress({
        address,
        chainIds
      });
      try {
        const contributionsWithTimestamp = await Promise.all(contributions.map(async contribution => {
          const publicClient = getPublicClient({
            chainId: contribution.chainId
          });
          const tx = await publicClient.getTransaction({
            hash: contribution.transactionHash
          });
          const block = await publicClient.getBlock({
            blockHash: tx.blockHash
          });
          return {
            ...contribution,
            timestamp: BigInt(block.timestamp)
          };
        }));
        setState({
          type: "loaded",
          data: {
            chainIds: chainIds,
            data: contributionsWithTimestamp
          }
        });
      } catch (e) {
        console.error("Error fetching contribution history for all chains", e);
        setState({
          type: "error",
          error: "Error fetching contribution history for all chains"
        });
      }
    };
    fetchContributions();
  }, [chainIds, dataLayer, rawAddress]);
  return state;
};
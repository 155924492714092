import useSWR from "swr";
import { createISOTimestamp } from "../discovery/utils/createRoundsStatusFilter";
import { useDataLayer } from "data-layer";
export const useRounds = (variables, chainIds) => {
  var _query$data, _variables$first, _query$data2;
  const dataLayer = useDataLayer();
  const query = useSWR(
  // Cache requests on chainIds and variables as keys (when these are the
  // same, cache will be used instead of new requests)
  ["rounds", chainIds, variables], async () => {
    const [spamRounds, {
      rounds
    }] = await Promise.all([fetchSpamRounds(), dataLayer.getRounds({
      ...variables,
      first: 100,
      chainIds
    })]);
    return rounds.filter(round => {
      var _spamRounds$round$cha;
      return !((_spamRounds$round$cha = spamRounds[round.chainId]) !== null && _spamRounds$round$cha !== void 0 && _spamRounds$round$cha[round.id.toLowerCase()]) && round.strategyName !== "";
    });
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateFirstPage: false
  });
  const data = (_query$data = query.data
  // Limit final results returned
  ) === null || _query$data === void 0 ? void 0 : _query$data.slice(0, (_variables$first = variables.first) !== null && _variables$first !== void 0 ? _variables$first : (_query$data2 = query.data) === null || _query$data2 === void 0 ? void 0 : _query$data2.length);
  return {
    ...query,
    data
  };
};
export function filterRoundsWithProjects(rounds) {
  /*
  0 projects + application period is still open: show
  0 projects + application period has closed: hide
  */
  const currentTimestamp = createISOTimestamp();
  return rounds.filter(round => {
    var _round$applications;
    return new Date(round.applicationsEndTime).getTime() * 1000 > Date.parse(currentTimestamp) || (round === null || round === void 0 || (_round$applications = round.applications) === null || _round$applications === void 0 ? void 0 : _round$applications.length) > 0;
  });
}
const OVERRIDE_PRIVATE_ROUND_IDS = [/* Zuzalu Q1 Round */
"0xf89aad3fad6c3e79ffa3ccc835620fcc7e55f919"];
export const filterOutPrivateRounds = rounds => {
  return rounds.filter(round => round.roundMetadata.roundType !== "private" || OVERRIDE_PRIVATE_ROUND_IDS.includes(round.id.toLowerCase()));
};
// Temporary round curation to avoid spam
export async function fetchSpamRounds() {
  try {
    const spam = {};
    const csvContent = await fetch("https://docs.google.com/spreadsheets/d/10jekVhMuFg6IQ0sYAN_dxh_U-OxU7EAuGMNvTtlpraM/export?format=tsv").then(res => res.text());
    const rows = csvContent.split("\n");
    rows
    // skip the header row
    .slice(1).forEach(line => {
      const columns = line.split("\t");
      const url = columns[1];
      // extract chainId and roundId
      const regex = /https:\/\/(explorer|explorer-v1)\.gitcoin\.co\/#\/round\/(\d+)\/([0-9a-fA-Fx]+)/;
      const match = url.match(regex);
      if (match) {
        const chainId = parseInt(match[2]);
        const roundId = match[3].toLowerCase();
        spam[chainId] || (spam[chainId] = {});
        spam[chainId][roundId] = true;
      }
    });
    return spam;
  } catch (e) {
    return {};
  }
}